<template>
  <div class="artificialContainer">
    <section
      data-mu-loading-color="rgba(255, 255, 255, 1)"
      data-mu-loading-overlay-color="rgba(0, 0, 0, .6)"
      data-mu-loading-text="提交中，请稍后..."
      v-loading="loading"
    >
      <div>
        <!-- <img @click="closeAudit" class="close" src="../../assets/login/close.png" alt /> -->
        <!-- <h3>身份信息输入</h3> -->
        <p class="hint">实名认证身份信息影响结算正常发放，请仔细核对。</p>
      </div>
      <mu-form ref="form" :model="validateForm" class="mu-demo-form">
        <mu-form-item label="姓名" prop="username" :rules="usernameRules">
          <mu-text-field
            v-model="validateForm.username"
            prop="username"
          ></mu-text-field>
        </mu-form-item>
        <mu-form-item
          label="身份证号"
          prop="cardNumber"
          :rules="cardNumberRules"
        >
          <mu-text-field
            v-model="validateForm.cardNumber"
            prop="cardNumber"
          ></mu-text-field>
        </mu-form-item>
        <mu-form-item
          label="银行卡号"
          prop="bankNumber"
          :rules="bankNumberRules"
        >
          <mu-text-field
            v-model="validateForm.bankNumber"
            prop="bankNumber"
          ></mu-text-field>
        </mu-form-item>
        <mu-form-item class="submitStyle">
          <mu-button color="#f15a60" @click="onSubmit">{{
            submitTxt
          }}</mu-button>
        </mu-form-item>
      </mu-form>
    </section>
    <!-- 认证成功 -->
    <aside class="dialogStyle" v-show="showIsSuccessPage">
      <div>
        <h3>恭喜您，认证成功了！</h3>
        <img src="../assets/success.png" alt="" />
        <div>
          <mu-button
            style="margin-left: 0"
            color="#00b935"
            @click="onBackWechat"
            >确定</mu-button
          >
        </div>
      </div>
    </aside>
    <!-- 认证成功 end -->
    <!-- 认证失败 -->
    <aside class="dialogStyle" v-show="showIsErrorPage">
      <div>
        <h3>认证失败</h3>
        <p>{{ errorText }}</p>
        <div>
          <mu-button small @click="onBackWechat">退出</mu-button>
          <mu-button color="#ff805c" small @click="onReAuth"
            >重新认证</mu-button
          >
        </div>
      </div>
    </aside>
    <!-- 认证失败 end -->
  </div>
</template>

<script>
import axios from "axios";
import { setTimeout } from "timers";
import wx from "weixin-js-sdk";
export default {
  data() {
    return {
      usernameRules: [
        { validate: (val) => !!val, message: "必须输入用户名" },
        { validate: (val) => val.length >= 2, message: "用户名长度大于2" },
      ],
      bankNumberRules: [
        { validate: (val) => !!val, message: "必须输入银行卡号" },
        {
          validate: (val) => val.length >= 16 && val.length <= 20,
          message: "请输入正确的银行卡号",
        },
      ],
      cardNumberRules: [
        { validate: (val) => !!val, message: "必须输入身份证" },
        {
          validate: (val) => val.length == 18,
          message: "请输入18位身份证号码",
        },
      ],
      validateForm: {
        username: "",
        cardNumber: "",
        bankNumber: "",
      },
      submitTxt: "提交",
      imgKeysList: {},
      details: {},
      showIsSuccessPage: false,
      showIsErrorPage: false,
      errorText: "",
      loading: false,
    };
  },
  props: {
    detailsVal: {},
  },
  watch: {
    detailsVal() {
      this.getDetail();
    },
  },

  mounted() {
    if (this.detailsVal != undefined && this.detailsVal != "") {
      this.getDetail();
    }
  },
  methods: {
    getDetail() {
      this.showIsSuccessPage = false;
      this.showIsErrorPage = false;
      this.details = this.detailsVal;
      this.validateForm = {
        username: this.details.name,
        cardNumber: this.details.idcard.replace(/\s*/g, ""),
        bankNumber: this.details.bank_card_number.replace(/\s*/g, ""),
      };
      // console.log(this.details);
    },
    onReAuth() {
      this.showIsErrorPage = false;
      this.$emit("showInArtificial", false);
    },
    onBackWechat() {
      wx.miniProgram.navigateBack();
    },
    onSubmit() {
      window.scroll(0, 0);
      this.$refs.form.validate().then((result) => {
        if (result && this.submitTxt == "提交") {
          this.$confirm("信息输入无误，确认上传？", "提示").then(
            ({ result }) => {
              if (result) {
                this.loading = true;
                this.submitTxt = "提交中...";
                // console.log(this.imgKeysList);
                let url =
                  this.httpsBasic.url +
                  this.httpsBasic.user +
                  "Identity/submitIdentitySt";
                let params = {
                  token: this.$route.query.token,
                  name: this.validateForm.username,
                  idcard: this.validateForm.cardNumber,
                  bank_card_number: this.validateForm.bankNumber,
                };
                // console.log(params);
                axios
                  .post(url, params)
                  .then((res) => {
                    if (res.data.code == 200) {
                      this.submitTxt = "提交";
                      this.showIsSuccessPage = true;
                      this.loading = false;
                    } else if (res.data.code == -11 || res.data.code == -10) {
                      this.loading = false;
                      this.submitTxt = "提交";
                      this.$confirm(
                        " 登录失效，将跳转至授权页面！",
                        "提示"
                      ).then(({ result }) => {
                        if (result) {
                          wx.miniProgram.navigateBack();
                        }
                      });
                    } else {
                      this.showIsErrorPage = true;
                      this.errorText = res.data.message;
                      this.submitTxt = "提交";
                      this.loading = false;
                    }
                  })
                  .catch((err) => {
                    this.$toast.error("服务器出错了...");
                    this.submitTxt = "提交";
                  });
              }
              //
            }
          );
        }
      });
    },
    clear() {
      this.$refs.form.clear();
      this.validateForm = {
        username: "",
        cardNumber: "",
        bankNumber: "",
      };
    },
  },
};
</script>
<style lang="less">
.artificialContainer {
  .mu-form-item-label {
    font-size: 16px;
  }
  .mu-text-field-input {
    font-size: 18px;
    padding: 10px;
    border: unset;
  }

  .submitStyle {
    .mu-form-item-content {
      justify-content: center;
    }
  }
}
</style>

<style lang="less" scoped>
.artificialContainer {
  form {
    > div {
      margin-bottom: 40px;
      width: 100%;
      label {
        font-size: 30px;
        display: block;
        color: #666;
        // text-indent: 10px;
      }
      input {
        // border-bottom: 1px solid #ccc;
        width: 100%;
        padding: 4px 2px;
        font-size: 30px;
        line-height: 44px;
      }
    }
  }
  .mu-text-field-input {
    font-size: 16px;
    padding: 10px;
  }
  section {
    background: #fff;
    // margin: 18% 6%;
    padding: 120px 4%;
    border-radius: 10px;
    position: relative;
    z-index: 999;
    min-height: 100vh;
    .close {
      position: absolute;
      right: 20px;
      top: 30px;
      height: 60px;
    }
    > div {
      margin-bottom: 30px;
      h3 {
        font-size: 32px;
        line-height: 44px;
        margin-bottom: 10px;
        text-align: center;
        margin: 50px 0 30px;
      }
      p {
        font-size: 28px;
        color: #999;
      }
      .hint {
        background: url(../assets/hint.png) no-repeat;
        background-size: 40px;
        text-indent: 46px;
        margin: 30px 0 50px;
        line-height: 44px;
      }
    }
    ul {
      border: 1px solid #ccc;
      padding: 20px;
      margin-bottom: 30px;
      li {
        margin-bottom: 10px;
        label,
        p {
          font-size: 30px;
          line-height: 44px;
        }
      }
    }
  }

  .dialogStyle {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;
    > div {
      background: #fff;
      position: relative;
      padding: 30px 30px 50px;
      margin: 50% 6%;
      border-radius: 20px;
      min-height: 500px;
      h3 {
        font-size: 36px;
        margin: 50px 0 30px;
        text-align: center;
      }
      p {
        font-size: 28px;
        line-height: 52px;
        margin: 20px 4%;
        min-height: 200px;
        text-align: center;
      }
      > div {
        text-align: center;
      }
      button {
        margin-left: 40px;
        margin-top: 40px;
      }
      img {
        height: 140px;
        display: block;
        margin: 50px auto 60px;
      }
    }
  }
}
</style>
