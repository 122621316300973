import Vue from "vue";
import VueRouter from "vue-router";
import UpData from "../views/UpData.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "upData",
    component: UpData,
  },
  {
    path: "/userBankInfo",
    name: "userBankInfo",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "data" */ "../views/UserBankInfo.vue"),
  },
  {
    path: "/agreement/BZ",
    name: "agreementBZ",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "agreement" */ "../views/BZAgreement.vue"),
  },
  {
    path: "/agreement/YX",
    name: "agreementYX",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "agreement" */ "../views/YXAgreement.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
