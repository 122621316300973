<template>
  <div>
    <!-- header -->
    <!-- <header class="headerStyle">
      <a @click="backPage">返回</a>
      <h1>认证</h1>
    </header> -->
    <!-- header end -->
    <!-- content -->
    <section
      class="content"
      data-mu-loading-color="rgba(255, 255, 255, 1)"
      data-mu-loading-overlay-color="rgba(0, 0, 0, .6)"
      data-mu-loading-text="图片识别中，请稍后..."
      v-loading="loading"
      v-show="!showIsIndentity"
    >
      <div class="hintTitle">
        <p>
          为保障您的资金安全，请上传本人身份证与银行卡照片。（提示：身份证及银行卡照片需露出四个圆角，且清晰）
          <br />
          <span>杉泰健康严格保障您的信息安全</span>
        </p>
      </div>
      <div class="upData">
        <h3>请上传本人身份证照片</h3>
        <ul>
          <li>
            <img :src="imageBeforeUrl" alt="indentityBefore" />
            <input type="file" accept="image/*" @change="identityBeforePic" />
            <span v-show="hideIsCardFront">拍摄信息页</span>
          </li>
          <li>
            <img :src="imageAfterUrl" alt="indentityAfter" />
            <input type="file" accept="image/*" @change="identityAfterPic" />
            <span v-show="hideIsCardBack">拍摄国徽页</span>
          </li>
        </ul>
      </div>
      <div class="upData">
        <h3>请上传本人银行卡照片</h3>
        <ul>
          <li>
            <img :src="imageBankUrl" alt="bank" />
            <input type="file" accept="image/*" @change="identityBankPic" />
            <span v-show="hideIsBank">拍摄银行卡</span>
          </li>
        </ul>
      </div>
      <div class="submit">
        <mu-button color="#f15a60" @click="onSubmit">提交</mu-button>
      </div>
    </section>
    <!-- 身份认证 -->
    <identity
      v-show="showIsIndentity"
      v-on:showInArtificial="showInArtificial"
      :detailsVal="detailsVal"
    />
    <!-- 身份认证 end -->
  </div>
</template>
<script>
import * as qiniu from "qiniu-js";
import axios from "axios";
import wx from "weixin-js-sdk";
import identity from "@/components/identity.vue";
export default {
  data() {
    return {
      submitTxt: "提交",
      getToken1: "",
      getToken2: "",
      getToken3: "",
      imageBeforeUrl: require("../assets/card_front.png"),
      imageAfterUrl: require("../assets/card_back.png"),
      imageBankUrl: require("../assets/bank.png"),
      loading: false,
      showIsIndentity: false,
      detailsVal: "",
      hideIsCardFront: true,
      hideIsCardBack: true,
      hideIsBank: true,
    };
  },
  components: {
    identity,
  },
  mounted() {},
  methods: {
    showInArtificial() {
      this.showIsIndentity = false;
    },
    getUpImgToken(e, key) {
      let self = this;
      let url = this.httpsBasic.url + "cloud/Qiniu/getToken";
      axios
        .post(url, {
          count: 1,
        })
        .then((res) => {
          switch (key) {
            case 1:
              this.getToken1 = res.data.data;
              break;
            case 2:
              this.getToken2 = res.data.data;
              break;
            case 3:
              this.getToken3 = res.data.data;
              break;
          }
          var config = {
            useCdnDomain: true,
            region: qiniu.region.z0,
          };
          var putExtra = {
            fname: "",
            params: {},
            mimeType: null,
          };
          var observable = qiniu.upload(
            e.target.files[0],
            res.data.data.keys[0],
            res.data.data.token,
            putExtra,
            config
          );
          var observer = {
            next(res) {
              // console.log("上传中...");
            },
            error(err) {
              // console.log(err)
              self.$alert("错误码：" + err.code + "！请重新选择图片", "提示", {
                okLabel: "知道了",
              });
              switch (key) {
                case 1:
                  self.imageBeforeUrl = "";
                  break;
                case 2:
                  self.imageAfterUrl = "";
                  break;
                case 3:
                  self.imageBankUrl = "";
                  break;
              }
            },
            complete(res) {
              switch (key) {
                case 1:
                  // console.log("身份证正面上传成功");
                  break;
                case 2:
                  // console.log("身份证背面上传成功");
                  break;
                case 3:
                  // console.log("银行卡上传成功");
                  break;
              }
              // console.log(res);
            },
          };
          var subscription = observable.subscribe(observer); // 上传开始
          // subscription.unsubscribe(); // 上传取消
          // console.log(this.getToken);
        });
    },
    identityBeforePic(e) {
      // console.log(e.target.files[0]);
      if (e.target.files[0] != undefined) {
        this.hideIsCardFront = false;
        this.getUpImgToken(e, 1); //
        // base64
        let reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);
        reader.onloadend = () => {
          this.imageBeforeUrl = reader.result;
          // console.log(this.imageUrl);
        };
      }
    },
    identityAfterPic(e) {
      if (e.target.files[0] != undefined) {
        this.hideIsCardBack = false;
        this.getUpImgToken(e, 2); //
        let reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);
        reader.onloadend = () => {
          this.imageAfterUrl = reader.result;
        };
      }
    },
    identityBankPic(e) {
      if (e.target.files[0] != undefined) {
        this.hideIsBank = false;
        this.getUpImgToken(e, 3);
        let reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);
        reader.onloadend = () => {
          this.imageBankUrl = reader.result;
        };
      }
    },
    backPage() {
      this.$router.go(-1); //返回上一层
    },
    verify() {
      let fls = true;
      if (this.getToken1 == "") {
        this.$toast.message("请上传信息页照片");
        fls = false;
        return fls;
      }
      if (this.getToken2 == "") {
        this.$toast.message("请上传国徽页照片");
        fls = false;
        return fls;
      }
      if (this.getToken3 == "") {
        this.$toast.message("请上传银行卡照片");
        fls = false;
        return fls;
      }
      return fls;
    },
    onSubmit() {
      let fls = this.verify();
      if (fls) {
        this.loading = true;
        let url =
          this.httpsBasic.url + this.httpsBasic.user + "Identity/addIdentitySt";
        let params = {
          token: this.$route.query.token,
          idcard_img_front: this.getToken1.business_file_key, //身份证正面
          idcard_img_back: this.getToken2.business_file_key, //身份证背面
          bank_card_image: this.getToken3.business_file_key, //银行卡图片
        };
        // console.log(params);
        axios.post(url, params).then((res) => {
          if (res.data.code == 200) {
            this.loading = false;
            this.showIsIndentity = true;
            this.detailsVal = res.data.data;
          } else if (res.data.code == -11 || res.data.code == -10) {
            this.loading = false;
            this.submitTxt = "提交";
            this.$confirm(" 登录失效，将跳转至授权页面！", "提示").then(
              ({ result }) => {
                if (result) {
                  wx.miniProgram.navigateBack();
                }
              }
            );
          } else {
            this.$toast.message(res.data.message);
            this.loading = false;
          }
        });
      }
    },
  },
};
</script>
<style lang="less" scoped>
.content {
  background: #fff;
  min-height: 100vh;
  .hintTitle {
    // border-bottom: 1px solid #f2f2f2;
    padding: 100px 4% 10px;
    // background: rgba(0, 185, 53, 0.1);
    span {
      background: url(../assets/security.png) no-repeat;
      display: block;
      background-size: 30px;
      text-indent: 40px;
      line-height: 30px;
      margin: 10px 0;
      color: #aaa;
      font-size: 26px;
    }
    p {
      font-size: 28px;
      line-height: 52px;
    }
  }
  .upData {
    margin: 20px 4%;
    h3 {
      font-size: 30px;
      margin: 50px 0 30px;
    }
    ul {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      li {
        text-align: center;
        width: 48%;
        position: relative;
        border-radius: 10px;
        overflow: hidden;
        > input {
          opacity: 0;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          position: absolute;
          z-index: 1;
        }
        > span {
          position: absolute;
          bottom: 0;
          left: 0;
          line-height: 50px;
          width: 100%;
          background: #ff805c;
          display: block;
          font-size: 26px;
          color: #fff;
        }
        > img {
          height: 228px;
          width: 100%;
          object-fit: cover;
          border: 1px solid #ff805c;
          border-radius: 10px;
        }
      }
    }
  }
  .submit {
    text-align: center;
    margin-top: 100px;
    margin-bottom: 100px;
  }
}
</style>
