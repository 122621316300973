import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import Vuex from "vuex";
import httpsBasic from "./utils/auth";
Vue.prototype.httpsBasic = httpsBasic;
Vue.use(Vuex);

import MuseUI from "muse-ui";
import "muse-ui/dist/muse-ui.css";
Vue.use(MuseUI);
import "muse-ui-progress/dist/muse-ui-progress.css";
import NProgress from "muse-ui-progress";
Vue.use(NProgress);
new Vue({
  created() {
    this.$progress.start();
    this.$progress.done();
  },
});
import "muse-ui-loading/dist/muse-ui-loading.css"; // load css
import Loading from "muse-ui-loading";
Vue.use(Loading);
Vue.config.productionTip = false;

const ToastOption = {
  position: "top", // 弹出的位置
  time: 2000, // 显示的时长
  closeIcon: "close", // 关闭的图标
  close: true, // 是否显示关闭按钮
  successIcon: "check_circle", // 成功信息图标
  infoIcon: "info", // 信息信息图标
  warningIcon: "priority_high", // 提醒信息图标
  errorIcon: "warning", // 错误信息图标
};
import Toast from "muse-ui-toast";
Vue.use(Toast, ToastOption);

const MessageOption = {
  successIcon: "check_circle", // 成功图标
  infoIcon: "info", // 信息图标
  warningIcon: "priority_high", // 提醒图标
  errorIcon: "warning", // 错误图标
  iconSize: 24, // 图标大小
  width: 350, // 对话框的宽度
  maxWidth: "80%", // 对话框最大宽度
  className: "", // 对话框的样式
  okLabel: "确定", // 对话框确定按钮文字
  cancelLabel: "取消", // 对话框取消按钮文字
  transition: "scale",
};
import "muse-ui-message/dist/muse-ui-message.css";
import Message from "muse-ui-message";
Vue.use(Message, MessageOption);
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
